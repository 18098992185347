<template>
  <div>
    <b-card>
      <div class="text-center">
        <h3>Laporan Transaksi Cash<br />UTD RS OTISTA</h3>
        <p>Periode: 1 Januari 2022 - 31 Desember 2022</p>
      </div>

      <b-table
        striped
        hover
        small
        :items="transaksi.items"
        :fields="transaksi.fields"
      ></b-table>
    </b-card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { BCard, BTable } from "bootstrap-vue";

const transaksi = ref({
  fields: [
    { key: "no", label: "No" },
    { key: "tanggal", label: "Tanggal" },
    { key: "noFPUP", label: "NoFPUP" },
    { key: "namaPasien", label: "Nama Pasien" },
    { key: "bagian", label: "Bagian" },
    { key: "biaya", label: "Biaya" },
    { key: "wb", label: "WB" },
    { key: "tc1", label: "TC1" },
    { key: "tc2", label: "TC2" },
    { key: "fpp", label: "FPP" },
    { key: "pc", label: "PC" },
    { key: "lp", label: "LP" },
    { key: "ahf", label: "AHF" },
    { key: "bc", label: "BC" },
    { key: "we", label: "WE" },
    { key: "pcl", label: "PCL" },
    { key: "pcls", label: "PCLS" },
    { key: "x", label: "X" },
    { key: "total", label: "Total" },
    { key: "jumlah", label: "Jumlah" },
  ],

  items: [
    {
      no: 1,
      tanggal: "2022-01-01",
      noFPUP: "1234567890",
      namaPasien: "John Doe",
      bagian: "Bagian 1",
      biaya: 100000,
      wb: 100000,
      tc1: 100000,
      tc2: 100000,
      fpp: 100000,
      pc: 100000,
      lp: 100000,
      ahf: 100000,
      bc: 100000,
      we: 100000,
      pcl: 100000,
      pcls: 100000,
      x: 100000,
      total: 100000,
      jumlah: 100000,
    },
  ],
});
</script>
